import React, {useState, useEffect, useCallback, useContext} from 'react';
import {set, remove, get} from 'local-storage';
import {Hub} from 'aws-amplify/utils';
import {useApolloClient, useQuery} from '@apollo/client';
import {useMutation} from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import * as ls from 'local-storage';
import {Purchases} from '@revenuecat/purchases-js';

import {mutations, queries} from '../api/user';
import {fetchAuthSession, signOut} from 'aws-amplify/auth';
import {ampli} from '../ampli';

const ENABLE_INTERCOM = process.env.NEXT_PUBLIC_ENABLE_INTERCOM === 'true';

type IUserContext = {
  isLoggedIn: boolean;
  user: any;
  isAdmin: boolean;
  justSignedUp: boolean;
  paymentStatus: any;
  trialInitiatedOn?: string | number;
  paymentType: string;
  logout: () => Promise<boolean>;
  login: (token: string) => void;
  signup: () => void;
  pastSignup: () => void;
  updateUserAvadId: (avadId: string | string[]) => Promise<void>;
};

const UserContext = React.createContext<IUserContext>({
  isLoggedIn: null,
  user: {},
  isAdmin: false,
  justSignedUp: false,
  paymentStatus: null,
  trialInitiatedOn: undefined,
  paymentType: '',
  logout: async () => {
    return false;
  },
  login: (token: string) => {},
  signup: () => {},
  pastSignup: () => {},
  updateUserAvadId: async () => {},
});

export const TOKEN = 'TOKEN';
export const USER_LOCATION = 'USER_LOCATION';
export const AVAD_ID = 'AVAD_ID';

const defaultUser = {
  email: null,
  id: null,
};

function getUserFromToken(token) {
  if (token) {
    try {
      // Grabbing user object from token
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      return null;
    }
  }
  return null;
}

export function UserProvider(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [justSignedUp, setJustSignedUp] = useState(false);
  const [user, setUser] = useState(defaultUser);
  const [isAdmin, setIsAdmin] = useState(false);
  const [kochava, setKochava] = useState(null);

  if (typeof window !== 'undefined' && !kochava) {
    //in-browser check and !kochava prevents 'too many re-renders'
    if (props.kochava) setKochava(props.kochava);
  }

  const client = useApolloClient();

  const [updateLastLogin] = useMutation(mutations.UPDATE_LAST_LOGIN);
  const [updateUser] = useMutation(mutations.UPDATE_USER);
  const [validateCognitoAuth] = useMutation(mutations.VALIDATE_COGNITO_AUTH);

  //getAppUserRecord
  let paymentStatus;
  let trialInitiatedOn;
  let paymentType;
  const {data} = useQuery(queries.FETCH_APP_USER, {
    //loading and error can be destructured here to console this query
    variables: {id: user.id, origin: 'web'},
    skip: !user?.id,
  });
  if (data?.appUser) {
    trialInitiatedOn = data.appUser.trialInitiatedOn;
    paymentStatus = data.appUser.isPaymentCurrent;
    paymentType = data.appUser.paymentType;
  }

  const fetchUserLocation = useCallback(() => {
    const success = pos => {
      const {latitude, longitude} = pos.coords;
      set(USER_LOCATION, [longitude, latitude]);
    };
    const error = err => {
      console.debug('error fetching user location', err);
    };

    navigator.geolocation.getCurrentPosition(success, error);
  }, []);

  const updateUserValues = useCallback(
    user => {
      if (!user) {
        return;
      }

      if (typeof window !== 'undefined' && kochava) {
        kochava.registerIdentityLink('User ID', user.uuid);
        kochava.registerIdentityLink('Login', user.email);
      }

      if (user?.userName) {
        set('USER', user.userName);
      }

      if (user?.roleId === 1) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
      setUser(user);
    },
    [kochava],
  );

  useEffect(() => {
    const email: string = ls.get('USER');
    Sentry.setUser({
      email,
    });

    const Intercom = window['Intercom'];
    if (Intercom && ENABLE_INTERCOM && data?.appUser?.intercomHash) {
      Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'ytzsnf7z',
        user_id: data?.appUser?.uuid,
        user_email: data?.appUser?.email,
        user_hash: data?.appUser?.intercomHash,
      });
    } else if (Intercom && ENABLE_INTERCOM) {
      Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'ytzsnf7z',
        user_id: data?.appUser?.uuid,
        user_email: data?.appUser?.email,
      });
    }
  }, [data?.appUser?.intercomHash]);

  useEffect(() => {
    if (data?.appUser?.uuid) {
      Purchases.configure(process.env.NEXT_PUBLIC_REVENUE_CAT_PURCHASE_WEB_KEY, data?.appUser?.uuid);
    }
  }, [data?.appUser])

  const updateUserAvadId = useCallback(
    async avadId => {
      const token = get(TOKEN) || localStorage.getItem('token');
      const user = getUserFromToken(token);
      if (user) {
        const res = await updateUser({
          variables: {
            id: user.id,
            avad_id: avadId,
          },
        });
        const newToken = res?.data?.updateUser;
        setUser(getUserFromToken(newToken));
      }
      set(AVAD_ID, avadId);
    },
    [updateUser],
  );
  const logout = useCallback(async () => {
    try {
      await fetchAuthSession();
      await signOut();
    } catch (err) {
      // nothing to do here. user is logged out.
    }
    setUser(defaultUser);
    remove(TOKEN);
    client.clearStore();
    setIsLoggedIn(false);
    return true;
  }, [client]);

  const login = (token: string) => {
    // post-signup login
    set(TOKEN, token);
    const user = getUserFromToken(token);
    ampli.identify(user.uuid);
    updateUserValues(user);
    const avadId = get(AVAD_ID);
    if (avadId) {
      updateUserAvadId(avadId);
    }
    setIsLoggedIn(true);

    if (typeof window !== 'undefined' && kochava) {
      kochava.sendEvent('Registration Complete', {
        userId: user.uuid,
        userName: user.email,
      });
    }

    return user;
  };

  const checkCognito = useCallback(async () => {
    try {
      const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {};
      if (!accessToken || !idToken) return false;
      const validationResponse = await validateCognitoAuth({
        variables: {
          accessToken: accessToken.toString(),
          idToken: idToken.toString(),
        },
      });
      if (validationResponse.data.cognitoAuth) {
        login(validationResponse.data.cognitoAuth);
      } else {
        await logout();
      }
    } catch (err) {
      if (err.message === 'Invalid tokens') {
        await logout();
      }
    }
  }, []);

  useEffect(() => {
    // https://docs.amplify.aws/react-native/build-a-backend/auth/auth-events/
    const hubListenerCancelToken = Hub.listen('auth', ({payload}) => {
      switch (payload.event) {
        case 'signedIn':
          checkCognito();
          break;
      }
    });

    if (!isLoggedIn) {
      const token = get(TOKEN) || localStorage.getItem('token');
      if (token) {
        updateUserValues(getUserFromToken(token));
        setIsLoggedIn(true);
        updateLastLogin();
        return;
      } else {
        checkCognito();
      }
    }
    return () => {
      hubListenerCancelToken();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        user,
        isAdmin,
        justSignedUp,
        paymentStatus,
        trialInitiatedOn,
        paymentType,
        logout,
        login,
        signup: () => {
          setJustSignedUp(true);
        },
        pastSignup: () => {
          setJustSignedUp(false);
        },
        updateUserAvadId,
      }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
